// on-account-guest-checkout-state.ts
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
export interface IOnAccountGuestCheckoutState {
    appliedOnAccount: boolean;
    receiptEmail: string;
    rentOptIn: boolean;
    emailOptIn: boolean;
}

/**
 * OnAccountGuestCheckoutState - action input
 */
export class OnAccountGuestCheckoutStateInput implements IActionInput {
    public getCacheKey = () => `OnAccountGuestCheckoutState`;
    public getCacheObjectType = () => 'OnAccountGuestCheckoutState';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * OnAccountGuestCheckoutState - action
 */
export async function onAccountGuestCheckoutStateAction(
    input: OnAccountGuestCheckoutStateInput,
    ctx: IActionContext
): Promise<IOnAccountGuestCheckoutState> {
    return { appliedOnAccount: false, receiptEmail: '', rentOptIn: false, emailOptIn: false };
}

/**
 * OnAccountGuestCheckoutState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new OnAccountGuestCheckoutStateInput();
};

/**
 * OnAccountGuestCheckoutState - create action
 */
export default createObservableDataAction<IOnAccountGuestCheckoutState>({
    action: <IAction<IOnAccountGuestCheckoutState>>onAccountGuestCheckoutStateAction,
    input: createInput
});
