/* eslint-disable security/detect-object-injection */
import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { updateAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import {
    AttributeValueBase,
    AttributeTextValue,
    CartLine,
    SimpleProduct,
    CustomerAttribute,
    AsyncResult,
    CommerceProperty
} from '@msdyn365-commerce/retail-proxy';
import {
    ObjectExtensions,
    IDimensionForSelectedVariant,
    IDimensionValueForSelectedVariant
} from '@msdyn365-commerce-modules/retail-actions';
import { ICartState } from '../../modules/c-checkout/mod/state-interfaces';
import { IOnAccountGuestCheckoutState, OnAccountGuestCheckoutStateInput } from '../../actions/get-on-account-guest-checkout-state';

// Created Custom Interface for Cart Attributes
export interface ICartAttributes {
    attributeName: string;
    attributeValue: boolean | string;
}

// Set Customer Attribues
export const setCustomerAttribute = (attributeName: string, attributeValue: string): CustomerAttribute => {
    const privIdAttribute: CustomerAttribute = {
        Name: attributeName,
        KeyName: attributeName,
        ExtensionProperties: [],
        AttributeValue: {
            StringValue: attributeValue
        },
        NameTranslations: []
    };

    return privIdAttribute;
};

// Find  Customer Attributes if exist update it other wise push
export const findCustomerAttribute = async (
    currentAttributes: CustomerAttribute[],
    attributeObj: CustomerAttribute
): Promise<CustomerAttribute[]> => {
    const attributeIndex = currentAttributes.findIndex(
        (attribute: CustomerAttribute) => attribute.Name?.toLowerCase() === attributeObj.Name?.toLowerCase()
    );
    if (attributeIndex > -1) {
        currentAttributes[attributeIndex] = attributeObj;
    } else {
        currentAttributes.push(attributeObj);
    }

    return currentAttributes;
};

// Set Order and Cart Attribues
export const setNameAttribute = (attributeName: string, attributeValue: string): AttributeValueBase => {
    const privIdAttribute = {
        // @ts-ignore
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
        Name: attributeName,
        ExtensionProperties: [],
        TextValue: attributeValue,
        TextValueTranslations: []
    };

    return privIdAttribute as AttributeTextValue;
};

// Find attribute if exist update it other wise push

export const findAttribute = async (
    currentAttributes: AttributeTextValue[],
    attributeObj: AttributeTextValue
): Promise<AttributeTextValue[]> => {
    const attributeIndex = currentAttributes.findIndex(
        (attribute: AttributeTextValue) => attribute.Name?.toLowerCase() === attributeObj.Name?.toLowerCase()
    );

    if (attributeIndex > -1) {
        currentAttributes[attributeIndex] = attributeObj;
    } else {
        currentAttributes.push(attributeObj);
    }

    return currentAttributes;
};

// Set Extension Properties
export const setExtensionProperties = (attributeName: string, attributeValue: string | boolean | number): CommerceProperty => {
    const privIdExtensionProperty: CommerceProperty = {
        Key: attributeName
    };
    if (typeof attributeValue === 'string') {
        privIdExtensionProperty.Value = {
            StringValue: attributeValue
        };
    } else if (typeof attributeValue === 'boolean') {
        privIdExtensionProperty.Value = {
            BooleanValue: attributeValue
        };
    }

    return privIdExtensionProperty;
};

// Find Extension Properties if exist update it other wise push
export const findExtensionProperty = async (
    currentProperties: CommerceProperty[],
    attributeObj: CommerceProperty
): Promise<CommerceProperty[]> => {
    const propertyIndex = currentProperties.findIndex(
        (property: CommerceProperty) => property.Key?.toLowerCase() === attributeObj.Key?.toLowerCase()
    );

    if (propertyIndex > -1) {
        currentProperties[propertyIndex] = attributeObj;
    } else {
        currentProperties.push(attributeObj);
    }

    return currentProperties;
};

// Find Extension Properties if exist update it other wise push
export const findToRemoveExtensionProperty = async (
    currentProperties: CommerceProperty[],
    attributeObj: CommerceProperty
): Promise<CommerceProperty[]> => {
    const propertyIndex = currentProperties.findIndex(
        (property: CommerceProperty) => property.Key?.toLowerCase() === attributeObj.Key?.toLowerCase()
    );

    if (propertyIndex > -1) {
        currentProperties.splice(propertyIndex, 1);
    }

    return currentProperties;
};

export const getNBFlagForCartLine = (cartline: CartLine): boolean | undefined => {
    const NBFlag: AttributeTextValue | undefined = cartline.AttributeValues?.find(attr => attr.Name === 'NonBackOrderableFlag');
    if (NBFlag && NBFlag.TextValue) {
        const value = JSON.parse(NBFlag.TextValue);
        return value;
    }
    return;
};

// ITS - Customization to get dimension value for selected product
export const getDiemnsionValueForSelectedProduct = (
    productDimensions: IDimensionForSelectedVariant[],
    product: SimpleProduct
): IDimensionValueForSelectedVariant | undefined => {
    const dimensions = (productDimensions[0].dimensionValuesWithInventory ??
        productDimensions[0].DimensionValues ??
        []) as IDimensionValueForSelectedVariant[];
    let dimension = dimensions.find(
        value =>
            !ObjectExtensions.isNullOrUndefined(value.DimensionValue?.RecordId ?? value.RecordId) &&
            value.ProductIds &&
            value.ProductIds.find(prod => prod === product.RecordId)
    );
    dimension = dimension && dimension.DimensionValue;
    return dimension;
};

export const getNBFlagFromProductDimensions = (
    productDimensions: IDimensionForSelectedVariant[],
    product: SimpleProduct
): boolean | undefined => {
    const dimension = getDiemnsionValueForSelectedProduct(productDimensions, product);
    const NBFlag =
        dimension &&
        dimension.ExtensionProperties &&
        dimension.ExtensionProperties[0] &&
        dimension.ExtensionProperties[0].Key === 'NonBackOrderableFlag'
            ? dimension.ExtensionProperties[0].Value?.BooleanValue
            : false;
    return NBFlag;
};
// ITS - Customization End

// ITS Customization
// export const updateCartAttributes = async (checkoutCart: ICartState, attributeName: string, attributeValue: string) => {
//     const newCart = {...checkoutCart.cart};
//     const newAttribute = setNameAttribute(attributeName, attributeValue);
//     let currentAttributes: AttributeValueBase[] = newCart?.AttributeValues || [];
//     currentAttributes = await findAttribute(currentAttributes, newAttribute);
//     newCart.AttributeValues = currentAttributes; // Updates cart with Attribute Values
//     updateCart(checkoutCart, newCart);
//     // await checkoutCart.updateAttributeValues({ newAttributeValues: newCart.AttributeValues })
// };

export const updateCartExtensionProperties = async (
    checkoutCart: ICartState,
    attributeName: string,
    attributeValue: string,
    context?: ICoreContext
) => {
    const newCart = { ...checkoutCart.cart };
    const newProperty = setExtensionProperties(attributeName, attributeValue);
    let currentExtensionProperties: CommerceProperty[] = newCart?.ExtensionProperties || [];
    currentExtensionProperties = await findExtensionProperty(currentExtensionProperties, newProperty);
    newCart.ExtensionProperties = currentExtensionProperties; // Updates cart with Extension Properties
    // newCart.ExtensionProperties && await updateAsync({callerContext: context?.actionContext!}, newCart);
    newCart.ExtensionProperties && (await checkoutCart.updateExtensionProperties({ newExtensionProperties: newCart.ExtensionProperties }));
};

export const removeCartExtensionProperties = async (checkoutCart: ICartState, attributeName: string, context?: ICoreContext) => {
    const newCart = { ...checkoutCart.cart };
    const currentExtensionProperties: CommerceProperty[] = newCart?.ExtensionProperties || [];
    const newExtensionProperties = currentExtensionProperties.filter(
        (property: CommerceProperty) => property.Key?.toLowerCase() !== attributeName.toLowerCase()
    );
    newCart.ExtensionProperties = newExtensionProperties; // Updates cart with Extension Properties
    console.log(newCart.ExtensionProperties, newExtensionProperties);
    newCart.ExtensionProperties && (await updateAsync({ callerContext: context?.actionContext! }, newCart));
    // newCart.ExtensionProperties && await checkoutCart.updateExtensionProperties({ newExtensionProperties: newCart.ExtensionProperties });
};

export const updateCartProperties = async (checkoutCart: ICartState, attributesArr: ICartAttributes[]) => {
    const newCart = { ...checkoutCart.cart };
    let currentExtensionProperties: CommerceProperty[] = newCart?.ExtensionProperties || [];
    await (async function() {
        for (const attr of attributesArr) {
            const newProperty = setExtensionProperties(attr.attributeName, attr.attributeValue);
            currentExtensionProperties = await findExtensionProperty(currentExtensionProperties, newProperty);
        }
    })();
    newCart.ExtensionProperties = [...currentExtensionProperties]; // Updates cart with Extension Properties
    newCart.ExtensionProperties && (await checkoutCart.updateExtensionProperties({ newExtensionProperties: newCart.ExtensionProperties }));
};
// ITS Customization
// export const updateCartAttributes = async (checkoutCart: ICartState, attributesArr: ICartAttributes[]) => {
//     const newCart = {...checkoutCart.cart};
//     let currentAttributes: AttributeValueBase[] = newCart?.AttributeValues || [];
//     await (async function() {
//         for (const attr of attributesArr) {
//             const newAttribute = setNameAttribute(attr.attributeName, attr.attributeValue);
//             currentAttributes = await findAttribute(currentAttributes, newAttribute);
//             newCart.AttributeValues = currentAttributes; // Updates cart with Attribute Values
//             console.log('newCart Attrbiutes::::', newCart.AttributeValues, ' with the attribuite::::', attr);
//         }
//     })();
//     newCart.AttributeValues && await checkoutCart.updateAttributeValues({ newAttributeValues: newCart.AttributeValues });
//     console.log('newCart Attrbiutes::::', newCart.AttributeValues);
// };

export const updateContext = (context: ICoreContext<IGeneric<IAny>>, key: string, attributeValue: boolean | string) => {
    const input = new OnAccountGuestCheckoutStateInput();
    const value = context.actionContext.get(input.getCacheObjectType(), input.getCacheKey()) as AsyncResult<IOnAccountGuestCheckoutState>;
    context.actionContext.update(input, { ...value.result, [key]: attributeValue });
};
